.workforce-staff {
  .staff-tabel-card {
    border-radius: 16px;

    .card-body {
      padding: 16px;
    }

    // .app-search {
    //   height: auto;
    // }
    .nav-border-top {
      border-bottom: none;
      @media (max-width: 430px) {
        display: flex;
        justify-content: space-evenly;
      }
    }

    .leave-table {
      .table-centered {
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 600;
              color: $Text-Dark;
              padding: 16px;
            }
          }
        }

        tbody {
          tr {
            padding: 8px 0px;

            td {
              font-size: 14px;
              font-weight: 400;
              color: $Text-Medium2;
              padding: 16px;

              p {
                font-size: 12px;
                font-weight: 400;
                color: $Text-Light1;
              }

              h5 {
                font-size: 14px;
                font-weight: 400;
                color: $Text-Medium;
              }

              .Approve {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Pending {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Reject {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: #3762ea,
              }

              .Invited {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }

              .Active {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Inactive {
                font-size: 12px;
                font-weight: 600;
                color: $Text-Light1;
              }

              .active-status {
                font-size: 10px;
                font-weight: 400;
                color: $Text-Light1;
              }

              .Approved {
                font-size: 14px;
                font-weight: 600;
                color: $Background-Success;
              }

              .Partially {
                font-size: 14px;
                font-weight: 600;
                color: $States-Warning;
              }

              .Rejected {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
              .Delete {
                font-size: 14px;
                font-weight: 600;
                color: $States-Negative_Error;
              }
            }
          }
        }
      }
    }

    .btn-employee-section {
      .nav-border-top-primary .nav-link.active {
        padding: 12px 16px;
        border-radius: 4px;
        border-top: 2px solid var(--Primary-Dark);
        opacity: 0.92;
        font-size: 14px;
        font-weight: 600;
        color: $Primary-Dark;
        display: flex;
        align-items: center;
        gap: 8px;
        .ri-group-line {
          font-size: 18px;
        }
        .ri-archive-line {
          font-size: 18px;
        }
      }
      .nav-border-top .nav-link {
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #707070;
        .ri-group-line {
          font-size: 18px;
        }
        .ri-archive-line {
          font-size: 18px;
        }
      }
    }
  }
}

.clear-section {

  .clear-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);

    @media (max-width: 991px) {
      margin-bottom: 8px;
    }
  }
}

.Apply-section {
  text-align: end;

  .next-btn {
    padding: 0.75rem 1.5rem;
    color: $Background-White;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    background: $Primary-Gradient;
  }
}

.add-new {
  border-radius: 24px 24px 0px 0px;

  .add-header {
    padding: 32px 24px 20px 24px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;

      @media(max-width:375px){
        font-size: 20px !important;
      }
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .offcanvas-body {
    // overflow-y: hidden;
    padding: 24px !important;

    @media (max-width: 1000px) {
      .col {
        flex: auto !important;
      }
    }

    .remove-btn-div {
      @media (max-width: 1000px) {
        justify-content: center;
        .remove-btn {
          margin-top: 0;
          font-size: 25px;
        }
      }
    }
  }

  .flatpickr-input {
    border-radius: 8px;
    border: 1px solid var(--text-Light-3, $Text-Light3);
    background: var(--Background-White, $Background-White);
  }

  .download-btn {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: none !important;
    border-radius: 8px;
    color: #F0484F;
    border: none;
  }


  .uploadsection {
    display: flex;
    padding: 5px 0px 8px 20px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border: 1px solid $Text-Light3;
    border-radius: 8px;

    p {
      margin-bottom: 0;
      padding: 5px;
      font-size: 13px;
      font-weight: 400;
      color: rgba(71, 71, 71, 0.8);
    }

    .uploadlabel {
      width: 103px;
      height: 35px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $Background-White;
      border-radius: 4px;
      border: 1px solid $Primary-Medium;
      font-size: 11px;
      gap: 8px;
      margin-bottom: 0;
      background-color: $Primary-Medium;

      .bx-plus {
        font-size: initial;
      }
    }
  }

  .closeuser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5.5px;
    border: none;
    padding-right: 20px !important;
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    background: none !important;
    border: none;
    padding: 0;
  }

  .remove-btn {
    font-size: 32px;
    background: none !important;
    border: none;
    color: $States-Negative_Error;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}

.time-off-canvas {
  min-height: 500px !important;
  padding: 1.5rem;
  border-radius: 24px 24px 0px 0px;
  .offcanvas-title {
    color: $Text-Dark;
    padding-bottom: 0.75rem;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.375rem;
  }
  .ri-calendar-event-line,
  .ri-time-line,
  .ri-calendar-check-line,
  .ri-book-open-line {
    color: $Text-Light2;
  }
  .custom-padding {
    padding: 0.75rem 0 0 0 !important;
  }
}
.unavailibility-canvas {
  min-height: 510px !important;
  border-radius: 24px 24px 0px 0px;
  padding: 1.5rem;
  .offcanvas-title {
    color: $Text-Medium;
    padding-bottom: 0.75rem;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.375rem;
  }
  .radio-label {
    color: $Text-Dark;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
  .ri-calendar-event-line,
  .ri-time-line,
  .ri-calendar-check-line,
  .ri-book-open-line {
    color: $Text-Light2;
  }
  .custom-padding {
    padding: 0.75rem 0 0 0 !important;
  }
}

.uploadsection {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border: 1px solid $Text-Light3;
  border-radius: 8px;

  p {
    margin-bottom: 0;
    padding: 5px;
    font-size: 13px;
    font-weight: 400;
    color: rgba(71, 71, 71, 0.8);
  }

  .uploadlabel {
    width: 103px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $Background-White;
    border-radius: 4px;
    border: 1px solid $Primary-Medium;
    font-size: 11px;
    gap: 8px;
    margin-bottom: 0;
    background-color: $Primary-Medium;

    .bx-plus {
      font-size: initial;
    }
  }
}

.closeuser {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-leave {
  border-radius: 24px 24px 0px 0px;
  min-height: 530px !important;

  .add-header {
    padding: 32px 24px 20px 24px;
    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .offcanvas-body {
    overflow-y: hidden;

    @media (max-width: 990px) {
      overflow-y: scroll;
    }
    @media (max-width: 755px) {
      .col {
        flex: auto;
      }
    }
  }

  .download-btn {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    color: $Background-White;
    border-radius: 8px;
    background: #F0484F;
  }


    .next-btn {
          color: $Background-White;
          font-size: 14px;
          font-weight: 600;
          border-radius: 0.5rem;
          border: none;
          background:$Primary-Gradient;
          display: flex;
          align-items: center;
          gap: 8px;
  
          .bi-plus {
            font-size: 16px;
          }
        }

  .flatpickr-input {
    border-radius: 8px;
    border: 1px solid var(--text-Light-3, $Text-Light3);
    background: var(--Background-White, $Background-White);
  }

  .uploadsection {
    display: flex;
    padding: 5px 0px 8px 20px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border: 1px solid $Text-Light3;
    border-radius: 8px;

    p {
      margin-bottom: 0;
      padding: 5px;
      font-size: 13px;
      font-weight: 400;
      color: rgba(71, 71, 71, 0.8);
    }

    .uploadlabel {
      width: 103px;
      height: 35px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $Background-White;
      border-radius: 4px;
      border: 1px solid $Primary-Medium;
      font-size: 11px;
      gap: 8px;
      margin-bottom: 0;
      background-color: $Primary-Medium;

      .bx-plus {
        font-size: initial;
      }
    }
  }

  .closeuser {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5.5px;
    border: none;
    padding-right: 20px !important;
  }

  .off-bottom {
    padding: 12px;
    // margin-top: 80px;
    border-top: 1px solid $Text-Light3;
    display: flex;
    justify-content: space-between;

    @media (max-width:430px){
      justify-content: center !important;
    }

    .form-check-label {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
    }

    .off-confirm {
      padding: 8px 2px;
      .next-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
        margin-left: 24px;
      }

      .clear-btn {
        padding: 0.75rem 1.5rem;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        background: none;
        border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
      }
    }
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }

  .remove-btn {
    font-size: 32px;
    background: none;
    border: none;
    color: $States-Negative_Error;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}

.add-people {
  border-radius: 24px 24px 0px 0px;
  padding: 24px;

  .add-header {
    padding: 0;
    padding-bottom: 20px !important;

    .offcanvas-title {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Medium;
    }

    .edit-btn {
      font-size: 14px;
      font-weight: 600;
      padding: 12px;
      color: $Background-White;
      border-radius: 8px;
      background: $Primary-Gradient;
      width: 80px;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Text-Light2;
    }

    .ri-close-line {
      font-size: 25px;
      color: $Text-Light2;
      padding: 0;
    }
  }

  .offcanvas-body {
    // overflow-y: hidden !important;
    @media (max-width: 719px) {
      .user-details-rs {
        flex: auto !important;
        padding: 0;
      }
      .user-details-section-col {
        padding: 0;
      }
    }
  }

  .user-details-section-col .user-details-col{
   @media (max-width:430px){
    margin-bottom: 16px;
   }
  }

  @media (max-width: 719px) {
    .details-section {
      // margin-top: 16px !important;
      height: auto;
    }
  }

  .details-section-second {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);

    .card-body {
      padding: 16px;
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
    }

    @media (max-width: 991px) {
      .emp-type {
        margin-top: 0px !important;
      }
    }

    .emp-type {
      margin-top: 24px;

      .detail-title {
        font-size: 14px;
        font-weight: 400;
        color: $Text-Medium3;
        margin-bottom: 8px;
      }

      .detail-value {
        font-size: 14px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 0;
        line-height: 15.9px;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }
  }

  .details-section-four {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);

    .card-body {
      padding: 16px;
    }

    .rs-details {
      margin-top: 24px;
    }
    @media (max-width: 991px) {
      .rs-details {
        margin-top: 0px !important;
      }
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 24px;
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }
  }

  .locationmap {
    border-radius: 12px;

    .gmaps {
      width: 100%;
      height: calc(100vh - 43.3vh);
    }
  }

  .details-section-third {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);

    // margin-left: 24px;
    // margin-bottom: 24px;

    .card-body {
      padding: 16px;
    }

    h5 {
      font-size: 18px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 12px;
    }

    .view-calender {
      text-align: end;

      p {
        font-size: 13px;
        font-weight: 600;
        color: $Primary-Medium;
      }
    }

    .detail-title {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .detail-value {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
      margin-bottom: 0;
      line-height: 15.9px;
    }

    .table {
      thead {
        tr {
          th {
            font-size: 12px;
            color: $Text-Medium2;
            font-weight: 600;
            padding: 16px 16px 16px 0px;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px;
            color: $Text-Medium3;
            font-weight: 400;
            padding: 16px 16px 16px 0px;
          }
        }
      }
      .ri-eye-line {
        font-size: 18px;
        color: $Text-Medium2;
        margin-right: 8px;
        border-radius: 4px;
        background: $Text-Light3;
        padding: 4px;
      }

      .bx-edit {
        font-size: 18px;
        color: $Text-Medium2;
        border-radius: 4px;
        background: $Text-Light3;
        padding: 4px;
      }
    }
  }

  .details-section {
    border-radius: 16px;
    background: var(--Background-BG, $Background-BG);
    height: 100%;

    @media (max-width: 719px) {
      height: auto;
    }

    .card-body {
      padding: 24px;
    }

    .edit-main {
      text-align: center;
      margin-bottom: 32px;
    }

    .profile {
      max-width: 100%;
      margin-bottom: 16px;
    }

    h5 {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
      margin-bottom: 8px;
    }

    .propemployee {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 8px;
    }

    .proptype {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 0;
    }

    .detail-people {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium2;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 12px;

      .icon-profile {
        font-size: 18px;
        color: $Primary-Medium;
      }
    }
  }

  .add-btn {
    color: $Primary-Medium;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }

  .remove-btn {
    font-size: 32px;
    background: none;
    border: none;
    color: $States-Negative_Error;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}

.off-bottom-staff {
  padding: 12px 24px;
  border-top: 1px solid $Text-Light3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 430px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .form-check-label {
    font-size: 14px;
    font-weight: 400;
    color: $Text-Medium3;
  }

  .off-confirm {
    padding: 8px 2px;
    .next-btn {
      padding: 0.75rem 1.5rem;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      border: none;
      background: $Primary-Gradient;
      margin-left: 24px;
    }

    .clear-btn {
      padding: 0.75rem 1.5rem;
      color: $Primary-Dark;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      background: none;
      border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
    }
  }
}

.edit-staff {
  .overflow-hidden {
    border-radius: 16px;

    .detail-people {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium2;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 12px;

      .icon-profile {
        font-size: 18px;
        color: $Primary-Medium;
      }
    }
    .calc-social-div {
      border-top: 1px solid #eaeaea;
      padding-top: 24px;
      display: flex;
      gap: 16px;
    }

    .card-footer {
      .archive-employee-btn {
        text-align: center;

        h5 {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
          color: $Text-Medium;
          cursor: pointer;

          span {
            font-size: 14px;
            font-weight: 600;
            color: $Primary-Medium;
          }
        }
      }
    }

    .profile-status {
      font-size: 14px;
      font-weight: 400;
      color: $Text-Medium3;
      margin-bottom: 8px;
    }

    .card-title {
      margin-bottom: 16px !important;
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
    }

    .user-name {
      font-size: 24px;
      font-weight: 600;
      color: $Text-Dark;
    }

    .user-role {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
    }

    .next-btn {
      padding: 8px;
      color: $Background-White;
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 4px;
      border: none;
      background: $Primary-Gradient;
    }
  }

  .back-bnt-btn {
    padding: 0.75rem 1.5rem;
    color: $Primary-Medium;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid var(--Primary-rad-Medium, $Primary-Medium);
    background: none;
    @media (max-width: 991px) {
      margin-bottom: 8px;
    }
  }

  .sub-update-btn {
    padding: 12px 16px;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    background: linear-gradient(90deg, #d40d12 8.15%, #ff4f5b 82.74%);
  }

  .sub-cancel-btn {
    padding: 12px 16px;
    color: $Primary-Dark;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 8px;
    border-color: $Primary-Dark;
    background: none;
  }

  .user-section {
    .nav-secondary .nav-link.active {
      border-radius: 8px;
      opacity: 0.92;
      background: var(--Primary-rad-Medium, $Primary-Medium);
      font-size: 14px;
      font-weight: 600;
      color: $Background-White;
    }

    .nav-secondary .nav-link {
      font-size: 14px;
      font-weight: 600;
      color: $Text-Medium2;
    }

    .arrow-navtabs{
      @media (max-width:617px){
        justify-content: space-evenly;
      }
    }

    .nav-secondary.arrow-navtabs .nav-link.active::before {
      border-top-color: $Primary-Medium;
    }

    .section-1 {
      border-radius: 16px;

      .card-header {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom: 1px solid $Text-Light3;
        padding-top: 24px;
      }

      // .card-body {
      //   min-height: 614px;
      // }

      .card-footer {
        padding: 12px 24px 24px 24px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      .dashboard-btn {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }
    

      .flatpickr-input {
        border-radius: 8px;
        border: 1px solid var(--text-Light-3, $Text-Light3);
        background: var(--Background-White, $Background-White);
      }
      .basic-details-title {
        font-size: 16px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 16px;
      }

      .personal-details-title {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Dark;
        padding-bottom: 12px;
      }

      .cancel-btn {
        padding: 16px;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border-color: $Primary-Dark;
        background: none;
        width: 146px;
      }

      .next-btn {
        padding: 16px;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border: none;
        background: $Primary-Gradient;
        width: 146px;
      }

      .input-title {
        margin-bottom: 19.2px;
        font-size: 16px;
        font-weight: 600;
        color: $Text-Dark;
      }

      .uploadsection {
        display: flex;
        padding: 4px 0px 4px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border: 1px solid $Text-Light3;
        border-radius: 8px;

        p {
          margin-bottom: 0;
          padding: 5px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(71, 71, 71, 0.8);
        }

        .uploadlabel {
          width: 103px;
          height: 35px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $Background-White;
          border-radius: 4px;
          border: 1px solid $Primary-Medium;
          font-size: 11px;
          gap: 8px;
          margin-bottom: 0;
          background-color: $Primary-Medium;

          .bx-plus {
            font-size: initial;
          }
        }
      }

      .closeuser {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5.5px;
        border: none;
        padding-right: 20px !important;
      }

      .add-btn {
        color: $Primary-Medium;
        font-size: 14px;
        font-weight: 600;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .update-pay-rate-title-div{
        @media (max-width:1065px){
          margin-top: 12px;
        }
      }

      .update-pay-rate-title {
        font-size: 14px;
        font-weight: 500;
        color: $Text-Dark;
      }

      .payrate-details-sections .payrate-details-main-div{
        @media (max-width:1065px){
          flex-direction: column;
        }
      }

         .payrate-details-sections .payrate-details-main-div .pay-rate-details{
        @media (max-width:1065px){
          margin-bottom: 0;
        }
      }

      .pay-rate-details {
        border-radius: 8px;
        background: var(--Background-BG, $Background-BG);
        width: -webkit-fill-available;
        padding: 8px 12px;
        margin-bottom: 24px;

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: $Text-Medium3;
          margin-bottom: 0px;
        }

        .pay-rate-price {
          display: flex;
          align-items: center;
          gap: 8px;

          .ri-coins-line {
            font-size: 24px;
            color: $Text-Light2;
          }

          p {
            font-size: 14px;
            font-weight: 600;
            color: $Text-Medium2;
            margin-bottom: 0;
          }
        }
      }
    }

    .section-document {
      border-radius: 16px;

      .card-body {
        min-height: 614px;
      }

      .add-company {
        padding: 0.75rem 1.5rem;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 0.5rem;
        border: none;
        background: $Primary-Gradient;
      }

      .card-header {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        border-bottom: 1px solid $Text-Light3;
        padding-top: 24px;
      }

      .card-footer {
        padding: 12px 24px 24px 24px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      .document-adjust-div{
        @media (max-width:1400px){
          padding-left: 15px !important;
          margin-top: 0 !important;
          margin-bottom: 1rem;
        }
      }


  .add-break-checkbox {
    padding: 8px;
    border-radius: 8px;
    background: var(--Background-BG, $Background-BG);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    .form-check-input {
      margin: 0;
      width: 18px;
      height: 18px;
    }
  }

      .flatpickr-input {
        border-radius: 8px;
        border: 1px solid var(--text-Light-3, $Text-Light3);
        background: var(--Background-White, $Background-White);
      }

      .basic-details-title {
        font-size: 16px;
        font-weight: 600;
        color: $Text-Medium2;
        margin-bottom: 16px;
      }

      .personal-details-title {
        font-size: 18px;
        font-weight: 600;
        color: $Text-Dark;
        padding-bottom: 12px;
      }

      .cancel-btn {
        padding: 16px;
        color: $Primary-Dark;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 8px;
        border-color: $Primary-Dark;
        background: none;
        width: 146px;
      }

      .next-btn {
        padding: 16px;
        color: $Background-White;
        font-size: 0.875rem;
        font-weight: 600;
        width: 146px;
        border-radius: 8px;
        border: none;
        background: $Primary-Gradient;
      }

      .input-title {
        margin-bottom: 19.2px;
        font-size: 16px;
        font-weight: 600;
        color: $Text-Dark;
      }

      .add-doc {
        margin-right: 15px;
      }

      .add-doc .upload-section-col{
        @media (max-width:1400px){
          margin-bottom: 0.8rem;
        }
      }

      .uploadsection {
        display: flex;
        padding: 5px 0px 5px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border: 1px solid $Text-Light3;
        border-radius: 8px;

        p {
          margin-bottom: 0;
          padding: 5px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(71, 71, 71, 0.8);
        }

        .uploadlabel {
          width: 103px;
          height: 35px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $Background-White;
          border-radius: 4px;
          border: 1px solid $Primary-Medium;
          font-size: 11px;
          gap: 8px;
          margin-bottom: 0;
          background-color: $Primary-Medium;

          .bx-plus {
            font-size: initial;
          }
        }
      }

      .closeuser {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5.5px;
        border: none;
        padding-right: 20px !important;
      }

      .add-btn {
        color: $Primary-Medium;
        font-size: 14px;
        font-weight: 600;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .calendar-wrapper {
      .fc-header-toolbar {
        padding-bottom: 1.5rem !important;
        margin-bottom: 1.5rem !important;
        border-bottom: 2px dashed $Text-Light2 !important;
      }
      .fc-toolbar-chunk {
        .fc-today-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          color: $Text-Dark !important;
          border: none;
          padding: 8px !important;
        }

        .fc-prev-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          color: $Primary-Medium;
          padding: 8px !important;
          border: none;
          margin-right: 8px;
        }

        .fc-next-button {
          border-radius: 4px !important;
          background: $Background-BG !important;
          color: $Primary-Medium;
          padding: 8px !important;
          border: none;
        }

        .btn-group .btn.active {
          background: $Primary-Medium !important;
          border: none;
          margin: 0px !important;
        }
      }
      .fc-daygrid-day {
        height: 95px;
        .fc-daygrid-day-events {
          margin-inline: 0.5rem;
          .fc-event {
            border-radius: 0.25rem !important;
          }
        }
      }
      .fc {
        border-radius: 1rem;
        .fc-dayGridMonth-view {
          padding-top: 0;
        }
      }
      .fc-day-today {
        background-color: #f8f8f8;
        border: 2px solid var(--tb-border-color) !important;
      }
      .calendar-footer {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-event {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 12px 16px;
          color: $Background-White;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 0.5rem;
          border: none;
          background: $Primary-Gradient;
          .ri-add-line {
            font-size: 16px;
          }
        }
      }
      .cancel-shift {
        background-color: $States-Negative_light;
        color: $States-Negative_Error;
      }
      .completed-shift {
        background-color: $States-Success_Light;
        color: $Background-Success;
      }
    }
  }
}

.archive-canvas {
  .offcanvas-header {
    .offcanvas-title {
      font-size: 24px;
      font-weight: 500;
      color: $Text-Medium;
    }
  }

  .archive-info-note {
    margin: 0px 16px;
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    background: var(--Background-BG, $Background-BG);

    .ri-information-line {
      font-size: 24px;
      color: $Primary-Medium;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      color: $Text-Medium2;
    }
  }

  .user-sec {
    padding: 24px 16px;
  }

  .user-name {
    font-size: 24px;
    font-weight: 500;
    color: $Text-Medium;
  }

  .user-role {
    font-size: 12px;
    font-weight: 400;
    color: $Text-Light1;
  }
}

.leave-canvas {
  .offcanvas-header {
    .offcanvas-title {
      font-size: 24px;
      font-weight: 500;
      color: $Text-Medium;
    }
  }

  .leave-panel-status {
    padding: 24px 0px 0px 0px;

    .Rejected {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Negative_light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Negative_Error;
      text-align: center;
    }

    .Pending {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Warning-light;
      font-size: 12px;
      font-weight: 600;
      color: $States-Warning;
      text-align: center;
    }

    .Approved {
      padding: 12px 16px;
      border-radius: 8px;
      background: $States-Success_Light;
      font-size: 12px;
      font-weight: 600;
      color: $Background-Success;
      text-align: center;
    }
  }

  .leave-panel {
    padding: 24px 0px;
    border-bottom: 1px solid $Text-Light3;

    .emp-name {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
      color: $Text-Medium3;
    }

    .emp-title {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium;
      margin-bottom: 0px;
    }

    .emp-pdf {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
      color: $Primary-Medium;
      display: flex;
      gap: 8px;

      .bx-file {
        font-size: 20px;
        color: $Primary-Medium;
      }
    }

    .emp-note {
      font-size: 14px;
      font-weight: 500;
      color: $Text-Medium2;
      margin-bottom: 0px;
    }
  }
}
